import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function Navbar(props) {
    return (
        <>
            <nav className="bg-white w-full z-20 top-0 start-0 border-b border-gray-300 dark:border-gray-700 sticky dark:bg-gray-900">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 dark:bg-gray-900">
                    <Link to={props.redirectPath} className="flex items-center space-x-3">
                        <img src="https://storage.googleapis.com/revuflow-assets/unshaped_logo.png" className="h-8" alt="Unshape logo" />
                        <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Unshape</span>
                    </Link>
                    <div className="flex md:order-2 space-x-3 md:space-x-0">
                        <Link to={props.stripeUrl} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center" target="_blank" rel="noreferrer">
                            Sign up now
                        </Link>
                    </div>
                    <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
                        <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg md:space-x-8 md:flex-row md:mt-0 md:border-0 bg-white dark:bg-gray-900">
                            <li>
                                <button id="dropdownNavbarLink" data-dropdown-toggle="dropdownNavbar"
                                        className="flex text-gray-900 items-center justify-between w-full py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white">Platform <svg
                                    className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" strokeLinecap={"round"} strokeLinejoin={"round"}
                                          strokeWidth="2" d="m1 1 4 4 4-4"/>
                                </svg></button>
                                <div id="dropdownNavbar"
                                     className="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200"
                                        aria-labelledby="dropdownLargeButton">
                                        <li>
                                            <a href={"/bluesky-bot/"}
                                               className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Bluesky by Unshape</a>
                                        </li>
                                        <li>
                                            <a href={"/mastodon-fediverse/"}
                                               className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Mastodon/Fediverse by Unshape</a>
                                        </li>
                                        <li>
                                            <a href={"/threads/"}
                                               className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Threads by Unshape</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <Link to={props.redirectPath + "#features"}
                                      className="block py-2 px-3 bg-blue-700 rounded md:bg-transparent md:p-0 text-blue-700 dark:text-blue-500"
                                      aria-current="page">Features</Link>
                            </li>
                            <li>
                                <Link to={props.redirectPath + "#how-it-works"}
                                      className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white">How
                                    it works</Link>
                            </li>
                            <li>
                                <Link to={props.redirectPath + "#pricing"}
                                      className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white">Pricing</Link>
                            </li>
                            <li>
                                <Link to={props.redirectPath + "#workflow-suggestions"}
                                      className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white">Workflow
                                    suggestions</Link>
                            </li>
                            <li>
                                <Link to={props.redirectPath + "#faq"}
                                      className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white">FAQ</Link>
                            </li>
                            <li>
                                <Link to={"/blog/"}
                                      className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white">Blog</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
