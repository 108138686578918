import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function Footer(props) {
    return (
        <>
            <footer className="bg-white rounded-lg p-4 dark:bg-gray-900">
                <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
                    <span className="text-xs text-gray-500 sm:text-center md:text-left break-word w-3/12">© 2023 <Link
                        to={props.redirectPath} className="hover:underline">Unshape™</Link>. All Rights Reserved. <br/>{props.integration} is a registered trademark of {props.integration}, Unshape is not affiliated in any way with {props.integration}.</span>
                    <ul className="flex flex-wrap items-center mt-3 justify-between text-xs font-medium text-gray-500 sm:mt-0">
                        <li>
                            <Link to={"/privacy-policy"} className="hover:underline me-4 md:me-6">Privacy
                                policy</Link>
                        </li>
                        <li>
                            <Link to={"/terms-of-service"} className="hover:underline me-4 md:me-6">Terms of
                                service</Link>
                        </li>
                        <li>
                            <Link to={"https://billing.stripe.com/p/login/6oE7tg8Tp5Zj11K6oo"}
                                  className="hover:underline me-4 md:me-6">Customer portal</Link>
                        </li>
                        <li>
                            <Link to={"/blog"} className="hover:underline me-4 md:me-6">Our blog</Link>
                        </li>
                        <li>
                            <Link
                                target="_blank"
                                to={"https://uptime.betterstack.com"}>
                                <img style={{width: '130px', height: '52px'}}
                                     alt="Better Stack Website Monitoring"
                                     src="https://uptime.betterstack.com/assets/static_assets/badges/light.png"/>
                            </Link>
                        </li>
                    </ul>
                </div>
            </footer>
        </>
    );
}

export default Footer;
