import React from 'react';
import {Helmet} from "react-helmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

function PrivacyPolicyPage() {
    return (
        <>
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <title>Unshape - Privacy policy</title>
                <meta property="og:title" content="Unshape - Privacy policy" />
            </Helmet>
            <Navbar></Navbar>

            <div className="mx-auto max-w-screen-xl py-16">
                <h1 className="mb-4 text-center text-3xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-white md:text-5xl lg:text-6xl">Privacy policy</h1>
                <div className="mx-4 md:mx-24 lg:mx-48 xl:mx-72 mt-16">
                    <p className="mb-5 text-gray-500 dark:text-gray-400"><span className="text-blue-600">Unshape</span> started life as a way to create posts on Bluesky/Mastodon/Threads to the outside world through Zapier and Make.</p>
                    <p className="mb-5 text-gray-500 dark:text-gray-400">I (Thibault Dassise, founder of <span className="text-blue-600">Unshape</span>) do not store your Bluesky/Mastodon/Threads username and password, only Zapier and Make does.</p>
                    <p className="mb-5 text-gray-500 dark:text-gray-400">I do not share user data with third parties under any circumstances.</p>
                    <p className="mb-5 text-gray-500 dark:text-gray-400">All revenue comes from paid subscriptions, removing the need for any type of tracking.</p>
                    <p className="mb-5 text-gray-500 dark:text-gray-400"><span className="text-blue-600">Unshape</span> does not store your payment information. Payment is handled through Stripe, but if you are uncomfortable using those services, alternative arrangements are available.</p>
                    <p className="mb-5 text-gray-500 dark:text-gray-400"><span className="text-blue-600">Unshape</span> earns all of its revenue through user subscriptions, and has no business relationship with large surveillance-based platforms like Facebook, Amazon or Google.</p>
                    <p className="mb-5 text-gray-500 dark:text-gray-400">If there is a privacy breach, I promise to
                        disclose it as soon as possible on my <a href="https://bsky.app/profile/tdassise.bsky.social" className="text-blue-600 underline">Bluesky</a>, <a href="https://mastodon.social/@tdassise" className="text-blue-600 underline">Mastodon</a> and <a href="https://www.threads.net/@thibaultu_" className="text-blue-600 underline">Threads</a> accounts.</p>
                    <p className="mb-5 text-gray-500 dark:text-gray-400">If you choose to delete your account, all your data will be removed after a 30-day waiting period.</p>
                    <p className="mb-5 text-gray-500 dark:text-gray-400">If you spot a privacy leak, please report it to <Link to={"mailto:support@unshape.app"} className="underline">support@unshape.app</Link>.</p>
                </div>

            </div>

            <Footer></Footer>
        </>
    );
}

export default PrivacyPolicyPage;
