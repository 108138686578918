import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import MastodonHomePage from "./pages/MastodonHomePage";
import BlueskyHomePage from "./pages/BlueskyHomePage";
import ThreadsHomePage from "./pages/ThreadsHomePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import {HelmetProvider} from 'react-helmet-async';
import BlogPage from "./pages/blog/BlogPage";
import ArticlePage from "./pages/blog/ArticlePage";

function App() {
    const helmetContext = {};
    return (
        <HelmetProvider context={helmetContext}>
            <Router>
                <Routes>
                    {/* Redirects */}
                    <Route path="/" element={<Navigate replace to="/bluesky-bot/" />} />
                    <Route path="/bluesky-bot/blog" element={<Navigate replace to="/blog" />} />
                    <Route path="/bluesky-bot/terms-of-service" element={<Navigate replace to="/terms-of-service" />} />
                    <Route path="/bluesky-bot/privacy-policy" element={<Navigate replace to="/privacy-policy" />} />

                    {/* Bluesky by Unshape */}
                    <Route path="/bluesky-bot/" element={<BlueskyHomePage/>}/>

                    {/* Mastodon by Unshape */}
                    <Route path="/mastodon-fediverse/" element={<MastodonHomePage/>}/>

                    {/* Threads by Unshape */}
                    <Route path="/threads/" element={<ThreadsHomePage/>}/>

                    {/* Globals */}
                    <Route path="/blog" element={<BlogPage/>}/>
                    <Route path="/blog/:slug" element={<ArticlePage/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicyPage/>}/>
                    <Route path="/terms-of-service" element={<TermsOfServicePage/>}/>
                </Routes>
            </Router>
        </HelmetProvider>
    );
}

export default App;
