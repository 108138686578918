import React from 'react';
import {HashLink as Link} from 'react-router-hash-link';
import {Helmet} from "react-helmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function ThreadsHomePage() {
    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <title>Unshape - Connect Threads to Zapier and Make</title>
                <meta name="description" content="Seamlessly automate your Threads posts in Zapier and Make with Unshape."/>
                <meta property="og:title" content="Unshape - Connect Threads to Zapier and Make"/>
                <meta property="og:description"
                      content="Enhance and automate your Threads posts with Unshape. Create engaging content, schedule posts, and manage your Threads interactions more efficiently."/>
            </Helmet>

            <Navbar redirectPath={"/threads/"} stripeUrl={"https://buy.stripe.com/dR6bINckx3Mu82Q4gh"}></Navbar>

            <section className="bg-white py-44 dark:bg-gray-900">
                <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
                    <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-6xl lg:text-7xl dark:text-white">Automate your Threads posts with <span className="text-transparent bg-clip-text bg-gradient-to-r from-red-400 to-orange-600">Zapier</span> and <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-400 to-purple-600">Make</span>
                    </h1>
                    <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48">Unlock the power of automation and revolutionize your Threads interactions with our Zapier and Make integrations.</p>
                    <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
                        <Link to={"https://zapier.com/developer/public-invite/194875/f59d7dccd0434bc87d91fbf205dd09b6/"} className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300">
                            Get the Zapier integration
                            <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                            </svg>
                        </Link>
                        <Link to={"https://eu2.make.com/app/invite/45487b25154ca6751bfcdc42856c7cf8"} className="inline-flex justify-center items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 dark:border-gray-700 hover:bg-gray-700 focus:ring-4 focus:ring-gray-100 dark:text-white">
                            Get the Make integration
                        </Link>
                    </div>
                </div>
            </section>

            <section id="features" className="bg-white dark:bg-gray-900">
                <div className="mx-4 lg:mx-auto dark:bg-gray-900 max-w-screen-xl mb-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-center gap-10 pb-20">
                    <div className="max-w-md px-6 p-6 bg-white border border-gray-200 dark:border-gray-600 rounded-lg shadow dark:bg-gray-800">
                        <div className="flex justify-center items-center rounded-full w-16 h-16 mb-4 border border-gray-300 dark:border-gray-500 dark:bg-gray-700" style={{boxShadow: "0 6px 71px 0 rgba(35, 35, 35, 0.09)"}}>
                            <div className="text-2xl">✨</div>
                        </div>
                        <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Craft engaging posts with ease</h5>
                        <p className="mb-5 font-normal text-gray-500">Enhance your Threads posts with rich text. Mention users, add URLs, hashtags and images easily for more interactive content.</p>
                    </div>
                    <div className="max-w-md px-6 p-6 bg-white border border-gray-200 dark:border-gray-600 rounded-lg shadow dark:bg-gray-800">
                        <div className="flex justify-center items-center rounded-full w-16 h-16 mb-4 border border-gray-300 dark:border-gray-500 dark:bg-gray-700" style={{boxShadow: "0 6px 71px 0 rgba(35, 35, 35, 0.09)"}}>
                            <div className="text-2xl">🌍️</div>
                        </div>
                        <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Automate seamlessly</h5>
                        <p className="mb-5 font-normal text-gray-500">Connect easily to Threads our Zapier & Make integrations and enjoy the power and flexibility of Zapier and Make.</p>
                    </div>
                    <div className="max-w-md px-6 p-6 bg-white border border-gray-200 dark:border-gray-600 rounded-lg shadow dark:bg-gray-800">
                        <div className="flex justify-center items-center rounded-full w-16 h-16 mb-4 border border-gray-300 dark:border-gray-500 dark:bg-gray-700" style={{boxShadow: "0 6px 71px 0 rgba(35, 35, 35, 0.09)"}}>
                            <div className="text-2xl">🖼️️</div>
                        </div>
                        <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Exciting features on the horizon</h5>
                        <p className="mb-3 font-normal text-gray-500">Discover Unshape's new features soon! Search for posts, likes and so on for an enriched Threads experience.</p>
                    </div>
                </div>
            </section>

            <section id="how-it-works" className="bg-gray-50 dark:bg-gray-800">
                <div className="max-w-screen-xl mx-auto py-16">
                    <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-800 text-center md:text-left md:text-5xl lg:text-6xl dark:text-white">How it works</h1>
                    <p className="text-lg font-normal text-center md:text-left text-gray-600 lg:text-xl dark:text-gray-400">Because a video is worth a thousand explanations.</p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 px-4 mt-10 md:mt-20">
                        <div>
                            <dl className="max-w-full md:pr-20 text-gray-900 divide-y divide-gray-300">
                                <div className="flex flex-col pb-6">
                                    <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Subscribe</dt>
                                    <dd className="text-lg font-semibold dark:text-gray-300">Step 1. <Link to={"https://buy.stripe.com/dR6bINckx3Mu82Q4gh"} target="_blank" rel="noreferrer"><mark className="px-2 text-white bg-blue-600 rounded dark:bg-blue-500">Subscribe to Threads by Unshape</mark></Link> for $6 a month <strong>using the same e-mail address as your Threads account</strong>.
                                    </dd>
                                </div>
                                <div className="flex flex-col py-6">
                                    <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Accept the Threads invite</dt>
                                    <dd className="text-lg font-semibold dark:text-gray-300">Step 2. Head Unshape to <Link to={"https://www.threads.net/settings/account"}><mark className="px-2 text-white bg-blue-600 rounded dark:bg-blue-500">Threads
                                      account settings > Website authorization</mark></Link> and accept the invite to Unshape.
                                    </dd>
                                </div>
                                <div className="flex flex-col pt-6">
                                    <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Automate</dt>
                                    <dd className="text-lg font-semibold dark:text-gray-300">Step 3. Connect Unshape to <Link to={"#"}><span className="text-transparent bg-clip-text bg-gradient-to-r from-red-400 to-orange-600">Zapier</span></Link> and start automating your <span className="text-blue-700">Threads</span> posts.
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        <div>
                            <iframe width="100%" height="100%" className="rounded-lg" src="https://www.youtube.com/embed/xQSI6YigdKo?si=egW3iINonNgfmS-j" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-white dark:bg-gray-900">
                <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Testimonials</h2>
                        <p className="mb-8 font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">See how Unshape is changing the game for our users.</p>
                    </div>
                    <div className="grid mb-8 lg:mb-12 lg:grid-cols-2">
                        <figure
                            className="flex flex-col justify-center items-center p-8 text-center bg-gray-50 border-b border-gray-200 md:p-12 lg:border-r dark:bg-gray-800 dark:border-gray-700">
                            <blockquote className="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">A must-have tool for marketers</h3>
                                <p className="my-4">"As a digital marketer, I am always on the lookout for tools that streamline my tasks. Unshape has been a game-changer in automating my Threads posts. It's intuitive, reliable, and a massive time-saver."</p>
                            </blockquote>
                            <figcaption className="flex justify-center items-center space-x-3">
                                <img className="w-9 h-9 rounded-full" src="/images/thispersondoesnotexist1.jpeg"
                                     alt="profile pic"></img>
                                <div className="space-y-0.5 font-medium dark:text-white text-left">
                                    <div>Alexis Moreau</div>
                                    <div className="text-sm font-light text-gray-500 dark:text-gray-400">Digital Marketing Specialist</div>
                                </div>
                            </figcaption>
                        </figure>
                        <figure
                            className="flex flex-col justify-center items-center p-8 text-center bg-gray-50 border-b border-gray-200 md:p-12 dark:bg-gray-800 dark:border-gray-700">
                            <blockquote className="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Revolutionized my Workflow</h3>
                                <p className="my-4">"Unshape has completely transformed the way I handle social media.
                                    The ability to schedule posts seamlessly through Zapier and Make has saved me
                                    countless hours. Highly recommend for any social media professional!"</p>
                            </blockquote>
                            <figcaption className="flex justify-center items-center space-x-3">
                                <img className="w-9 h-9 rounded-full" src="/images/thispersondoesnotexist2.png"
                                     alt="profile pic"></img>
                                <div className="space-y-0.5 font-medium dark:text-white text-left">
                                    <div>Emily Durand</div>
                                    <div className="text-sm font-light text-gray-500 dark:text-gray-400">Social Media
                                        Manager
                                    </div>
                                </div>
                            </figcaption>
                        </figure>
                        <figure
                            className="flex flex-col justify-center items-center p-8 text-center bg-gray-50 border-b border-gray-200 lg:border-b-0 md:p-12 lg:border-r dark:bg-gray-800 dark:border-gray-700">
                            <blockquote className="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Simplicity and
                                    efficiency at its best</h3>
                                <p className="my-4">"Unshape’s intuitive design has made it easy for me to schedule and
                                    manage my Threads content. As a freelancer, this kind of efficiency is invaluable.
                                    It's one of the best tools I've added to my arsenal."</p>
                            </blockquote>
                            <figcaption className="flex justify-center items-center space-x-3">
                                <img className="w-9 h-9 rounded-full" src="/images/thispersondoesnotexist3.jpeg"
                                     alt="profile pic"></img>
                                <div className="space-y-0.5 font-medium dark:text-white text-left">
                                    <div>Thomas Duflot</div>
                                    <div className="text-sm font-light text-gray-500 dark:text-gray-400">Freelance
                                        Content Creator
                                    </div>
                                </div>
                            </figcaption>
                        </figure>
                        <figure
                            className="flex flex-col justify-center items-center p-8 text-center bg-gray-50 border-gray-200 md:p-12 dark:bg-gray-800 dark:border-gray-700">
                            <blockquote className="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Innovative and
                                    Essential</h3>
                                <p className="my-4">"In the tech world, innovation is key. Unshape's integration with
                                    Threads through Zapier and Make is not just innovative, but essential for anyone
                                    looking to maximize their online presence. It's a game-changer!"</p>
                            </blockquote>
                            <figcaption className="flex justify-center items-center space-x-3">
                                <img className="w-9 h-9 rounded-full" src="/images/thispersondoesnotexist4.png"
                                     alt="profile pic"></img>
                                <div className="space-y-0.5 font-medium dark:text-white text-left">
                                    <div>Sophie Lefebvre</div>
                                    <div className="text-sm font-light text-gray-500 dark:text-gray-400">Tech
                                        Entrepreneur
                                    </div>
                                </div>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </section>

            <section id="pricing" className="bg-white pt-28 pb-40 dark:bg-gray-900">
                <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">Start
                            using Unshape</h2>
                        <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">Automate your <span
                            className="text-blue-700">Threads</span> posts for only $6 a month (or $5 paying yearly).
                        </p>
                        <Link to={"https://buy.stripe.com/dR6bINckx3Mu82Q4gh"} target="_blank" rel="noreferrer"
                              className="text-dark bg-white hover:bg-gray-400 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none border border-gray-300 hover:border-gray-400 dark:border-gray-700 dark:text-white">Pay
                            monthly</Link>
                        <Link to={"https://buy.stripe.com/dR61495W9aaSgzmbIM"} target="_blank" rel="noreferrer"
                              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">Pay
                            yearly</Link>
                    </div>
                </div>
            </section>

            <section id="workflow-suggestions" className="px-4 lg:px-6 xl:px-72 py-28 bg-gray-50 dark:bg-gray-800">
                <zapier-full-experience
                    client-id="osUoNp6R6MxBb1IempKEenNejWAqOUd37NSF59zP"
                    theme="auto"
                    app-search-bar-display="show"
                    app-categories="artificial-intelligence,calendar,notes"
                ></zapier-full-experience>
            </section>

            <section id="faq" className="mb-20 bg-white dark:bg-gray-900">
                <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                    <h2 className="mb-8 text-4xl text-center md:text-left tracking-tight font-extrabold text-gray-900 dark:text-white">Frequently asked questions</h2>
                    <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
                        <div>
                            <div className="mb-10">
                                <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-gray-200">
                                    <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                                    </svg>
                                    What is Unshape ?
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">Unshape is an innovative tool that integrates with Threads social media platform. It automates the process of creating and managing posts, offering features like rich text formatting, user mentions, URL integration, and hashtags.</p>
                            </div>
                            <div className="mb-10">
                                <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-gray-200">
                                    <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                                    </svg>
                                    How do I set up Unshape ?
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">Once you've subscribed to Unshape for $6 a month, go to Zapier or Make and create a new automation. Choose the triggering app of your choice, then define the action to be performed in Threads.</p>
                            </div>
                            <div className="mb-10">
                                <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-gray-200">
                                    <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor"
                                         viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                                    </svg>
                                    What kind of content can I automate with Unshape ?
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">Unshape allows you to automate various types of content, including text posts with rich formatting. Future updates will include the ability to respond to threads and add images to your posts.</p>
                            </div>
                            <div className="mb-10">
                                <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-gray-200">
                                    <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                                    </svg>
                                    Are there any tutorials or resources to learn more about Unshape ?
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">Our site describe how to use Unshape in Zapier and Make. We've also made a video so you can see how to use our product.</p>
                            </div>
                        </div>
                        <div>
                            <div className="mb-10">
                                <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-gray-200">
                                    <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                                    </svg>
                                    Is Unshape easy to use for beginners ?
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">Absolutely! Unshape is designed to be used with Zapier or Make, making it accessible for both beginners and advanced users. There are also guides available to help you get started.</p>
                            </div>
                            <div className="mb-10">
                                <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-gray-200">
                                    <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                                    </svg>
                                    How does Unshape handle privacy and data security ?
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">Currently, Unshape does not store any of your personal information.</p>
                            </div>
                            <div className="mb-10">
                                <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-gray-200">
                                    <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                                    </svg>
                                    Can I schedule posts with Unshape ?
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">Of course you can! Since Zapier and Make offer default triggers allowing you to choose a trigger date and time, you can use them to schedule posts at specific times.</p>
                            </div>
                            <div className="mb-10">
                                <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-gray-200">
                                    <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                                    </svg>
                                    How can I get support if I encounter issues with Unshape ?
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">For any support queries or technical issues, you can reach out to our support team via our support email (<a href="mailto:support@unshape.app">support@unshape.app</a>).</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer redirectPath={"/threads/"} integration={"Threads"}></Footer>
        </div>
    );
}

export default ThreadsHomePage;
