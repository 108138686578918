import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import {Link, useParams} from "react-router-dom";
import {createDirectus, readItems, rest} from "@directus/sdk";

function ArticlePage() {
    const {slug} = useParams();
    const [article, setArticle] = useState(null);

    useEffect(() => {
        const fetchArticle = async () => {
            const client = createDirectus('https://directus.tdassise.com/').with(rest());
            try {
                const response = await client.request(readItems('unshape_blog_articles_content', {
                    fields: ['*,card.*'],
                    filter: {
                        card: {
                            slug: {
                                _eq: slug
                            }
                        }
                    }
                }));
                setArticle(response[0]);
            } catch (error) {
                console.error('Error fetching article:', error);
            }
        };
        fetchArticle();
    }, [slug]);

    if (!article) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <title>Unshape - {article.title}</title>
                <meta property="og:title" content={`Unshape - ${article.title}`}/>
            </Helmet>
            <Navbar></Navbar>

            <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
                <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
                    <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                        <header className="mb-4 lg:mb-6 not-format">
                            <h1 className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">{article.title}</h1>
                        </header>
                        <p className="text-gray-500 dark:text-gray-400 mb-5">{article && article.headline}</p>

                        {article && article.article_content && renderBlocks(article.article_content.blocks)}
                        <p className="text-gray-500 dark:text-gray-400 my-10">{article && article.endline}</p>

                        <p className="text-gray-500 dark:text-gray-400">Ready to elevate your social media game? <Link to={"https://buy.stripe.com/dR6bINckx3Mu82Q4gh"} className="text-black dark:text-white underline" target="_blank" rel="noreferrer">Start your journey towards streamlined social media management today with Unshape!</Link></p>
                    </article>
                </div>
            </main>

            <Footer></Footer>
        </>
    );
}

function renderBlocks(blocks) {
    return blocks.map((block) => {
        switch (block.type) {
            case 'header':
                return <h2 className="mt-6 mb-2 font-semibold text-md text-gray-700 dark:text-gray-200 md:text-lg lg:text-xl">{block.data.text}</h2>;
            case 'paragraph':
                return <p className="text-gray-500 dark:text-gray-400" dangerouslySetInnerHTML={{ __html: decodeHtml(block.data.text) }}></p>;
            case 'nestedlist':
                return (
                    <ol className="max-w-xl space-y-3 text-gray-500 list-decimal list-inside dark:text-gray-400 pl-3">
                        {block.data.items.map((item, index) => (
                            <li key={index} dangerouslySetInnerHTML={{ __html: decodeHtml(item.content) }} />
                        ))}
                    </ol>
                );
            default:
                return null;
        }
    });
}

function decodeHtml(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export default ArticlePage;
