import React, {useEffect, useState} from 'react';
import {createDirectus, readItems, rest} from '@directus/sdk'
import {Helmet} from "react-helmet";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import ArticleCard from "../../components/ArticleCard";

function BlogPage() {
    const [articleCards, setArticleCards] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const client = createDirectus('https://directus.tdassise.com/').with(rest());
                let fetchedArticles = await client.request(readItems('unshape_blog_articles'));
                fetchedArticles = fetchedArticles.map(article => ({
                    ...article,
                    daysAgo: Math.floor((new Date() - new Date(article.date_updated ?? article.date_created)) / (1000 * 60 * 60 * 24))
                }));
                setArticleCards(fetchedArticles);
            } catch (e) {
                console.error(e);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <title>Unshape - Our blog</title>
                <meta property="og:title" content="Unshape - Our blog"/>
            </Helmet>
            <Navbar></Navbar>

            <section className="bg-white dark:bg-gray-900">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                        <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Our Blog</h2>
                        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Welcome to our blog, your go-to source for the latest updates on Unshape. Dive into our engaging tutorials, insightful articles, and all the newest developments surrounding our platform.</p>
                    </div>
                    <div className="grid gap-8 lg:grid-cols-2">
                        {articleCards.map(articleCard => (
                            <ArticleCard
                                key={articleCard.id}
                                slug={articleCard.slug}
                                daysAgo={articleCard.daysAgo}
                                type={articleCard.type}
                                name={articleCard.title}
                                headline={articleCard.headline}>
                            </ArticleCard>
                        ))}
                    </div>
                </div>
            </section>

            <Footer></Footer>
        </>
    );
}

export default BlogPage;
